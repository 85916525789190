import React from 'react';
import { graphql } from 'gatsby';
import AdditionalResources from '../../components/AdditionalResources';
import CastCard from '../../components/CastCard';
import { getKeyedResources } from '../../utils/resource-helper';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Quicklinks from '../../components/Quicklinks';
import Resource from '../../components/Resource';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
// import Callout from '../../components/Callout';
import MultiVideoPlayer from '../../components/MultiVideoPlayer';

const VFTPage = ({ data, pageContext }) => {
  const { t } = useTranslation(['educators-your-heart-vft', 'common', 'paths']);

  const {
    listenToYourHeart,
    movingTogether,
    tasteTestChallenge,
  } = getKeyedResources(data);

  const fadeInDelays = [0, 300, 600];

  const ENG_LANG = ['us', 'ir', 'uk', 'in'];

  return (
    <Layout title="Educators" className={pageContext.lang}>
      <Section>
        <MultiVideoPlayer
          videos={[
            {
              src: t('fullVftSrc'),
              poster: 'img-vft-thumb-full-vft.jpg',
              title: ENG_LANG.includes(pageContext.lang)
                ? 'Virtual Field Trip'
                : 'Del Viaje Virtual',
            },
            {
              src: t('chapter1Src'),
              poster: 'img-vft-thumb-chapter1.jpg',
              title: ENG_LANG.includes(pageContext.lang) ? 'Intro' : 'Intro',
            },
            {
              src: t('chapter2Src'),
              poster: 'img-vft-thumb-chapter2.jpg',
              title: ENG_LANG.includes(pageContext.lang)
                ? 'Utilities & Maintenance'
                : 'Utilidades y mantenimiento',
            },
            {
              src: t('chapter3Src'),
              poster: 'img-vft-thumb-chapter3.jpg',
              title: ENG_LANG.includes(pageContext.lang)
                ? 'Electrical Department & Kitchen'
                : 'Departamento eléctrico y cocina',
            },
            {
              src: t('chapter4Src'),
              poster: 'img-vft-thumb-chapter4.jpg',
              title: ENG_LANG.includes(pageContext.lang)
                ? 'Plumbing Department'
                : 'Departamento de fontanería',
            },
            {
              src: t('chapter5Src'),
              poster: 'img-vft-thumb-chapter5.jpg',
              title: ENG_LANG.includes(pageContext.lang)
                ? 'Preventative Maintenance'
                : 'Mantenimiento preventive',
            },
          ]}
        />
      </Section>
      {(pageContext.lang === 'ir' ||
        pageContext.lang === 'co' ||
        pageContext.lang === 'in' ||
        pageContext.lang === 'mx') && (
        <Section className="pt-1 pb-0">
          <Container>
            <h4 style={{ textAlign: 'center', fontSize: '1rem' }}>
              {t('ccDisclaimer')}
            </h4>
          </Container>
        </Section>
      )}
      <Section>
        <Container>
          <Row>
            <Column size={9}>
              <h2 className="with-heart text-uppercase">
                {t('section1Title')}
              </h2>
              <p>{t('section1Paragraph1')}</p>
            </Column>
          </Row>
          <Row>
            <Column>
              <Quicklinks
                className="text-sans-serif"
                title={t('common:quicklinksLabel').toUpperCase()}
                smoothScroll={true}
                links={[
                  {
                    target: '#educator-guide',
                    label: t('quicklink1').toUpperCase(),
                  },
                  {
                    target: '#educator-guide',
                    label: t('quicklink2').toUpperCase(),
                  },
                  {
                    target: '#family-activities',
                    label: t('quicklink3').toUpperCase(),
                  },
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column size={5}>
              <Image filename="img-vft-live.jpg" />
            </Column>
            <Column size={7}>
              <h2 className="intro__title">{t('section2Title')}</h2>
              <p>{t('section2Paragraph1')}</p>
              <p>
                <Button asButton to="#content">
                  {t('common:watchNow')}
                </Button>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ======================= CALLOUT ==================== */}
      {/* {pageContext.lang === 'us' && (
        <Section className="pt-0 pb-0">
          <Container>
            <Callout
              to="https://surveys.discoveryeducation.com/s3/Abbott-Post-Virtual-Field-Trip-Survey-Short-Version"
              alt={t('gettingStartedBannerAlt')}
              className="callout--banner callout--blue mb-0"
            >
              <div
                className="text-sans-serif"
                dangerouslySetInnerHTML={{ __html: t('vftCallout') }}
              />
            </Callout>
          </Container>
        </Section>
      )} */}
      {/* ======================= GUIDES ==================== */}
      <Section>
        <Container>
          <div id="educator-guide">
            <Row>
              <Column size={6}>
                <Resource
                  img="img-vft-educator-guide.jpg"
                  tophat={t('common:educatorGuide')}
                  title={t('common:educatorGuide')}
                  description={t('educatorVftDescription')}
                  actions={[
                    {
                      label: t('common:download'),
                      metadata: t('educatorVftGuideMetaData'),
                      download: t('educatorVftGuideUrl'),
                    },
                  ]}
                />
              </Column>
              <Column size={6}>
                <Resource
                  img="img-vft-employee-guide.jpg"
                  tophat={t('common:abbottEmployeeGuide')}
                  title={t('common:abbottEmployeeGuide')}
                  description={t('employeeVftDescription')}
                  actions={[
                    {
                      label: t('common:download'),
                      metadata: t('employeeVftGuideMetaData'),
                      download: t('employeeVftGuideUrl'),
                    },
                  ]}
                />
              </Column>
            </Row>
          </div>
        </Container>
      </Section>
      {/* ======================= MEET THE CAST ==================== */}
      <Section className="bg-color-purple">
        <Container>
          <Row>
            <Column size={9}>
              <h2
                style={{ textTransform: 'capitalize' }}
                className="intro__title color-white"
              >
                {t('castTitle').toLowerCase()}
              </h2>
              <p className="color-white">{t('castIntro')}</p>
            </Column>
          </Row>
          <Row>
            <Column size={5}>
              <CastCard
                category={t('castCard1Category')}
                img="img-vft-head-gaeta.jpg"
                name={t('castCard1Name')}
                descriptor={t('castCard1Descriptor1')}
              />
            </Column>
            <Column size={5} offset={1}>
              <CastCard
                category={t('castCard2Category')}
                img="img-vft-head-williams.jpg"
                name={t('castCard2Name')}
                descriptor={t('castCard2Descriptor1')}
              />
            </Column>
          </Row>
          <Row>
            <Column size={5}>
              <CastCard
                category={t('castCard3Category')}
                img="img-vft-head-wulf.jpg"
                name={t('castCard3Name')}
                descriptor={t('castCard3Descriptor1')}
              />
            </Column>
            <Column size={5} offset={1}>
              <CastCard
                category={t('castCard4Category')}
                img="img-vft-head-jones.jpg"
                name={t('castCard4Name')}
                descriptor={t('castCard4Descriptor1')}
              />
            </Column>
          </Row>
          <Row>
            <Column size={5}>
              <CastCard
                category={t('castCard5Category')}
                img="img-vft-head-tucker.jpg"
                name={t('castCard5Name')}
                descriptor={t('castCard5Descriptor1')}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ======================= FAMILY ACTIVITIES ==================== */}
      <Section id="family-activities">
        <Container>
          <h2 className="intro__title">{t('familiesTitle')}</h2>
          <p
            className="activities__ready-to-use-activities-body mb-2"
            dangerouslySetInnerHTML={{ __html: t('familiesIntro') }}
          />
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={listenToYourHeart.tophat}
                  title={listenToYourHeart.title}
                  duration={listenToYourHeart.duration}
                  description={listenToYourHeart.description}
                  img={listenToYourHeart.img}
                  actions={listenToYourHeart.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={tasteTestChallenge.tophat}
                  title={tasteTestChallenge.title}
                  duration={tasteTestChallenge.duration}
                  description={tasteTestChallenge.description}
                  img={tasteTestChallenge.img}
                  actions={tasteTestChallenge.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={movingTogether.tophat}
                  title={movingTogether.title}
                  duration={movingTogether.duration}
                  description={movingTogether.description}
                  img={movingTogether.img}
                  actions={movingTogether.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ======================= ADDITIONAL RESOURCES ==================== */}
      <Section>
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container>
            <h2 className="h3 text-serif">
              {t('common:exploreAdditionalResources')}
            </h2>
            <Row>
              <Column size={6}>
                <AdditionalResources
                  img="img-addl-resources-about-the-program.png"
                  heading={t('common:exploreEducatorResources')}
                  to={t('paths:/educators/').path}
                />
              </Column>
              <Column size={6}>
                <AdditionalResources
                  img="img-addl-resources-employees.png"
                  heading={t('common:exploreFamilyResources')}
                  to={t('paths:/families/').path}
                />
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query EducatorVFTActivitiesResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            download
            noIcon
            asLink
            linkFromImg
            inverse
            moduleName
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default VFTPage;
