import React from 'react';
import Image from '../Image';
import PropTypes from 'prop-types';
import './styles.scss';

const CastCard = ({ category, name, descriptor, img }) => {
  return (
    <article className="cast-card">
      <div className="cast-card__image">
        <Image filename={img} />
      </div>
      <div className="cast-card__text">
        <h4 className="with-heart cast-card__category">{category}</h4>
        <h3 className="cast-card__name">{name}</h3>
        <div dangerouslySetInnerHTML={{ __html: descriptor }}></div>
      </div>
    </article>
  );
};

CastCard.propTypes = {
  category: PropTypes.string.isRequired,
  /* Descriptors for the cast member, either string or array, will be normalized to array. */
  descriptor: PropTypes.any,
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CastCard;
